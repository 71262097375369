import React from 'react'

const Ministries = () => (
    <main role="main">
        <div className="container py-2"> 
            <h3 className="display-4 d-none d-lg-inline">教会事工 / CHURCH MINISTRIES</h3>
            <h2 className="d-none d-md-inline  d-lg-none">教会事工 / CHURCH MINISTRIES</h2>
            <h3 className="d-inline d-md-none">教会事工 / CHURCH MINISTRIES</h3>
            <div className="jumbotron" >
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <img className=" img-fluid mx-auto" alt="" src="/images/5c.png" />
                        </div>
                        <div className="col-md-9">
                            <h2 className="my-3">City Community Chinese Christian Church</h2>
                            <p>多伦多仁爱基督教会已经招募到一批基督勇士，他们将带着大诫命去完成大使命，把福音传给千千万万生活在多伦多市中心的青年同胞。他们在中国成长的，在北美接受教育，但是他们特别的背景，有特别的需要。 因疫情原因推迟，事工已予2022年6月正式启动。希望有负担的弟兄姐妹一起参与，也希望弟兄姐妹为这事工祷告。</p>
                            <a href="https://www.wuxichurch.ca">Welcome join City Community Chinese Christian Church / 欢迎参加 多伦多五溪基督教会</a>
                        </div>
                    </div> 
                </div>
            </div>

            <div className="row">
                <div className="col-md-7">
                    <h2 className="my-3">主日崇拜</h2>
                    <p>主日崇拜是仁爱教会弟兄姊妹每周来到圣殿中的聚集敬拜。弟兄姊妹们聚集在一起敬拜三一真神的伟大, 赞美祂的创造, 记念祂在十字架上为我们捨命。同时通过敬拜，弟兄姊妹的生命得到激励，爱主更深，使人生发仁爱的信心更得坚固，在平时的生活中，以生命来影响生命，活出基督的样式。 由于场地的原因，目前仁爱教会的主日崇拜在周六晚上进行。成人崇拜以国语为主，青少年崇拜以英语为主。</p>
                </div>
                <div className="col-md-5">
                    <img className=" img-fluid mx-auto" alt="" src="/images/family-worship2.jpg" />
                </div>
            </div>
            <hr/>
            <div className="row">
                <div className="col-md-5">
                    <img className=" img-fluid mx-auto" alt="" src="/images/c2.jpg" />
                </div>
                <div className="col-md-7">
                    <h2 className="my-3">敬拜诗班</h2>
                    <p>诗歌敬拜是仁爱教会每周聚集敬拜的一个重要组成部分。它是音乐与信仰的组合,也是上帝话语的宣扬。我们不仅以灵歌唱,也以悟性歌唱, 在敬拜赞美中表达对主的爱并享受与主相遇的甜美。诗歌敬拜使每一位参与者能在一种属灵的气氛中享受到与上帝亲密的时刻, 自由地敬拜并感受上帝荣耀的临在，宝血的遮盖以及圣灵的触摸与释放。</p>
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-md-7">
                    <h2 className="my-3">主日学</h2>
                    <p>仁爱教会在每周六敬拜结束后开设多个主日学班，以帮助弟兄姐妹在圣经真理上扎根，在基督里长大成人。借着圣经的教导，得以完全，预备行各样的善事。圣经里保罗勉励提摩太要持守他所学习的圣经真理和教训。今日我们同样处身于末后的日子，同样面对各种罪恶的境况。我们唯有多研读圣经，明白圣经，才有能力行出基督徒当行的道。</p>
                </div>
                <div className="col-md-5">
                    <img className=" img-fluid mx-auto" alt="" src="/images/c1.jpg" />
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-md-5">
                    <img className=" img-fluid mx-auto" alt="" src="/images/c3.jpg" />
                </div>
                <div className="col-md-7">
                    <h2 className="my-3">青少年和儿童</h2>
                    <p>仁爱教会开展JK-12不同年龄段的青少年和儿童事工。每周在专任青少年牧师的带领下，教导并鼓励孩子们认识并效法主基督。我们深信家庭对信仰的孕育最具影响力，青少年事工寻求与父母们同工，支持他们并装备他们扶育子女 。教会通过不同的项目和途径，帮助家庭建立一个适合孩子们健康成长的经历及彰显神恩典的温暖的家，训练及装备孩子们一生荣耀神。</p>
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-md-7">
                    <h2 className="my-3">团契生活</h2>
                    <p>团契生活是仁爱教会弟兄姊妹信仰生活的一个重要组成部分。教会根据不同弟兄姊妹的需要设立不同功能的团契小组，让弟兄姊妹籍着团契彼此接纳、分享、合一，建立属灵的情感和弟兄姊妹的情谊。 借着美好的肢体生活，互相激励，使信仰在生活中得到落实，使基督的身体得以完全。 各团契聚会在周末和周间分布在教会和不同的弟兄姊妹家中举行。</p>
                </div>
                <div className="col-md-5">
                    <img className=" img-fluid mx-auto" alt="" src="/images/c1.jpg" />
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-md-5">
                    <img className=" img-fluid mx-auto" alt="" src="/images/c2.jpg" />
                </div>
                <div className="col-md-7">
                    <h2 className="my-3">社区事工</h2>
                    <p>我们坚信：任何一个跟随耶稣基督的门徒都不应该对基督徒参与社会、政冶感到困惑，也不应该对教会所担当的社会关怀和社会公义的天职感到陌生。圣经记载耶稣基督在公开的事工中，不仅「教导、传道」(太四23，九35)，祂也「行善医病」(徒十38)。在教会的历史中，福音事工和社会关怀是密不可分的。仁爱教会的异象是：从一个社区教会(community church)迈向教会社区(church community)。</p>
                </div>
            </div> 
        </div>
    </main>
)

export default Ministries
