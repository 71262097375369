import axios from "axios";

const KEY = "AIzaSyBfO31U-dWHk3kA-v_jcRE3XNCBri5A2gM";

export default axios.create({
    baseURL: "https://www.googleapis.com/youtube/v3",
    params: {
        part: "snippet",
        type: "video",
        maxResults: 5,
        order: "date",
        key: KEY,
    },
});
