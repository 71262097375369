import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => (
    <header>
        <nav className="navbar navbar-expand fixed-top" style={{ background: "#7f0000" }}>
            <div className="container flex-column flex-md-row">
                <Link className="navbar-brand  " to='/'><img src="/images/rn.png" alt="Home Page" /></Link>
                <div className=" float-right d-none d-md-block">
                    <ul className="navbar-nav float-right mt-3" style={{ fontSize: "23px" }}>
                        <li className="nav-item">
                            <Link className="nav-link text-light text-nowrap" to='/ministries'>教会事工</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link text-light text-nowrap" to='/videos'>讲道录像</Link>
                        </li>
                        <li className="nav-item"><a className="nav-link  text-light text-nowrap" href="https://www.paypal.com/ca/fundraiser/charity/3473406">在线奉献</a>
                        </li>
                    </ul>
                </div>
                <div className="float-right d-md-none">
                    <ul className="navbar-nav flex-md-column" style={{ fontSize: "16px" }}>
                        <li className="nav-item">
                            <Link className="nav-link text-light text-nowrap" to='/ministries'>教会事工</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link text-light text-nowrap" to='/videos'>讲道录像</Link>
                        </li>
                        <li className="nav-item"><a className="nav-link  text-light text-nowrap" href="https://www.paypal.com/ca/fundraiser/charity/3473406">在线奉献</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
)

export default Header
