import React from 'react'

const Home = () => (
    <main role="main">
        <div className="jumbotron" style={{ background: "URL(/images/family-worship.jpg) no-repeat center top", backgroundColor: "#e2e3e5", height: "500px" }}>
            <div className="container  text-center">
                <h1 className="display-4">惟独使人生发<br /><b style={{ color: "#7f0000" }}>仁爱</b>的信心<br />才有功效</h1>
            </div>
        </div>

        <div className="container">
            <div className="row">
                <div className="col-md-4">
                    <h5><i className="far fa-clock fa-lg"></i> 聚会时间 / SERVICE SCHEDULE</h5>
                    <p>
                        周六晚 / Saturday Night<br />
                        18:00 聚餐 / Dinner <br />
                        19:00 崇拜 / Worship <small className="text-danger">(线上敬拜同步进行)</small><br />
                        20:30 主日学 / Bible Classes
                    </p>
                </div>
                <div className="col-md-4">
                    <h5><i className="far fa-map fa-lg"></i> 教会地址 / ADDRESS</h5>
                    <p>
                        211 Steeles Ave E (at Brebeuf College)<br />
                                North York, Ontario<br />
                                M2M 3Y6 Canada
                        <a className="ml-3" href="https://www.google.com/maps/place/211+Steeles+Ave+E,+North+York,+ON+M2M+2X1" rel="noreferrer" target="_blank"><i className="fas fa-map-marker-alt w3-text-red fa-sm" title="查看地图"></i></a>
                    </p>
                </div>
                <div className="col-md-4">
                    <h5><i className="far fa-address-card fa-lg"></i> 联系我们 / CONTACT US</h5>
                    <p>
                        <i className="fas fa-phone"></i> <a href="tel:+16477993300">647-799-3300</a><br />
                        <i className="fas fa-envelope"></i> <a href="mailto:info@renaichurch.com">info@renaichurch.com</a><br />
                        <i className="fas fa-gift"></i> <a href="https://www.paypal.com/ca/fundraiser/charity/3473406" target="paypal">线上奉献 Paypal</a> / <a href="mailto:donate@renaichurch.com">donate@renaichurch.com</a>
                    </p>
                </div>
            </div>
        </div>
        <div className="bg-secondary  py-4">
            <div className="container text-light py-4">
                <div className="  row  ">
                    <div className="col-md-7">
                        <h2 className="my-3">异象使命 / VISION & MISSION</h2>
                        <p>多伦多仁爱基督教会以圣经为原则，唯尊耶稣基督为元首，以宣扬十字架的救恩，造就信徒，建立肢体，推动宣教为己任。本教会带着向中国同胞广传福音的异象，致力于本地华人福音事工并积极支持和参与普世宣教，带着大诫命去完成大使命，使万民作主门徒，让神的名得荣耀。</p>
                    </div>
                    <div className="col-md-5">
                        <img className=" img-fluid mx-auto" alt="VISION & MISSION" src="/images/c1.jpg" />
                    </div>
                </div>
            </div>
        </div>

        <div className="container py-4">
            <div className="row">
                <div className="col-md-4 text-center">
                    <img src="/images/c4.jpg" alt="CHALLENGE" className="my-3 img-fluid mx-auto  rounded-circle" />
                    <h2>挑战 / CHALLENGE</h2>
                </div>
                <div className="col-md-4 text-center">
                    <img src="/images/c3.jpg" alt="CHANGE" className="my-3 img-fluid mx-auto  rounded-circle" />
                    <h2>改变 / CHANGE</h2>
                </div>
                <div className="col-md-4 text-center">
                    <img src="/images/c5.jpg" alt="CELEBRATE" className="my-3 img-fluid mx-auto rounded-circle" />
                    <h2>欢庆 / CELEBRATE</h2>
                </div>
            </div>
        </div>
        

        <div className="container">
            <div className="row">
                <div className="col-md-6 p-5">
                    <img className=" img-fluid mx-auto" alt="" src="/images/fellowship.jpg" />
                </div>
                <div className="col-md-6 p-5">
                    <img className=" img-fluid mx-auto" alt="" src="/images/offering.jpg" />
                </div>
            </div>
        </div>
    </main>
)

export default Home
