import React from 'react'
import Header from './components/Header'
import Main from './components/Main'


const App = () => (
    <div>
        <Header />
        <div className="alert alert-warning mb-0 alert-dismissible fade show" role="alert">
            <div className="container pl-5">
                <b> <i className="fas fa-info-circle fa-fw"></i> 通知</b><br />
                <i className="fa fa-building fa-fw"></i> 教会植堂  <a href="https://www.wuxichurch.ca" target="5c">City Community Chinese Christian Church / 多伦多五溪基督教会</a> 已予2022年6月正式启动, 欢迎参加!<br />
                <i className="fas fa-video fa-fw"></i> 教会已经实体聚会, 线上敬拜同步进行 <a href="https://zoom.us/j/5543640680?pwd=dGU0NXRDbTEraW1WbW42VFRvRnZ1Zz09" target="zoom">Zoom</a> 会议ID 554 364 0680 密码 000000 <br />
                <i className="fab fa-paypal fa-fw"></i> 教会线上奉献 <a href="https://www.paypal.com/ca/fundraiser/charity/3473406" target="paypal">Paypal</a> 或者 EMT <a href="mailto:donate@renaichurch.com">donate@renaichurch.com</a>
            </div>
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>  
        <Main />
        <footer className="py-4 border-top border-5 border-danger">
            <div className="container">
                <p>&copy;{(new Date().getFullYear())} - 多伦多仁爱基督教会 / Toronto Renai Chinese Christian Church </p>
            </div>
        </footer>
    </div >
)

export default App
